
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as colorOdOkf8mFn4Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/color.vue?macro=true";
import { default as cornersoC8e7udWABMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/corners.vue?macro=true";
import { default as iconsctGl1CfvnsMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/icons.vue?macro=true";
import { default as indexi7RYNWAdvLMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/index.vue?macro=true";
import { default as layoutUlzn0AXpcGMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/layout.vue?macro=true";
import { default as spacingmejJKNyLNBMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/spacing.vue?macro=true";
import { default as typographyJSlyhTdNFxMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/typography.vue?macro=true";
import { default as form_45field_45validationu0uNQYCdSXMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/examples/form-field-validation.vue?macro=true";
import { default as form_45validationDpUzMHdCxYMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/examples/form-validation.vue?macro=true";
import { default as indexfswcu4JzhgMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/examples/index.vue?macro=true";
import { default as indexG3Nzvmu4UaMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/index.vue?macro=true";
import { default as accordion6xESO7jwVyMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/accordion.vue?macro=true";
import { default as badgef1JGXczhJEMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/badge.vue?macro=true";
import { default as breadcrumbs8GUtKQXWjBMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/breadcrumbs.vue?macro=true";
import { default as buttonC065y1r5meMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/button.vue?macro=true";
import { default as cardl7e4kZIWI4Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/card.vue?macro=true";
import { default as checkboxbIE2GJ3EYjMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/checkbox.vue?macro=true";
import { default as collapsesWWhMkBAB4Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/collapse.vue?macro=true";
import { default as data_45table_45simplexXtFZHzAFNMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/data-table-simple.vue?macro=true";
import { default as data_45table4qcPRIZUxUMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/data-table.vue?macro=true";
import { default as file_45inputTPBd09OSvIMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/file-input.vue?macro=true";
import { default as form_45message52k628BoFGMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/form-message.vue?macro=true";
import { default as indexLH83AKynErMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/index.vue?macro=true";
import { default as modalyYk6yqSFFVMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/modal.vue?macro=true";
import { default as paginationJGvf8ADMPyMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/pagination.vue?macro=true";
import { default as popoverojojAOLEkcMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/popover.vue?macro=true";
import { default as progress_45circleMEqghUCYL6Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/progress-circle.vue?macro=true";
import { default as progressHVMalkLPtBMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/progress.vue?macro=true";
import { default as radio_45button5n1QZHxSboMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/radio-button.vue?macro=true";
import { default as radio_45cardsq2IM0rm75XMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/radio-cards.vue?macro=true";
import { default as rating9ZW1LWUdavMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/rating.vue?macro=true";
import { default as segmented_45controlKDsEJUHJJRMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/segmented-control.vue?macro=true";
import { default as skeleton5v4rYt448mMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/skeleton.vue?macro=true";
import { default as sliderdjwn2hmlkqMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/slider.vue?macro=true";
import { default as supportm38NVnOfqcMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/support.vue?macro=true";
import { default as tabscIDl4OCrZ8Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/tabs.vue?macro=true";
import { default as text_45inputRl3jQwGmlQMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/text-input.vue?macro=true";
import { default as textareazOF8ap2b3qMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/textarea.vue?macro=true";
import { default as verification_45codemQWrpaYcm9Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/verification-code.vue?macro=true";
import { default as videoROyjinU2s9Meta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/video.vue?macro=true";
import { default as view_45more6z5feiuEAIMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/view-more.vue?macro=true";
import { default as carouselmKIfRf1H6OMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/carousel.vue?macro=true";
import { default as cta_45bannerWzSq6JCZGAMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/cta-banner.vue?macro=true";
import { default as cta_45cardEkrmW4QwVPMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/cta-card.vue?macro=true";
import { default as error_45page3OkWOiB6NcMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/error-page.vue?macro=true";
import { default as file_45preview_45modalLCSHnNlqjbMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/file-preview-modal.vue?macro=true";
import { default as footer9tpWUOFK1wMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/footer.vue?macro=true";
import { default as form2mHEtPQ1PNMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/form.vue?macro=true";
import { default as indexfkfvceeY5nMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/index.vue?macro=true";
import { default as nav_45barcr3gCycKgfMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/nav-bar.vue?macro=true";
import { default as reviews_45io_45card_45carouselVl0hPlp70VMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/reviews-io-card-carousel.vue?macro=true";
import { default as support_45card7TVNwA41gFMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/support-card.vue?macro=true";
import { default as zip_45code_45formfuRDh0taklMeta } from "/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/zip-code-form.vue?macro=true";
export default [
  {
    name: "atoms-color",
    path: "/atoms/color",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/color.vue")
  },
  {
    name: "atoms-corners",
    path: "/atoms/corners",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/corners.vue")
  },
  {
    name: "atoms-icons",
    path: "/atoms/icons",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/icons.vue")
  },
  {
    name: "atoms",
    path: "/atoms",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/index.vue")
  },
  {
    name: "atoms-layout",
    path: "/atoms/layout",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/layout.vue")
  },
  {
    name: "atoms-spacing",
    path: "/atoms/spacing",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/spacing.vue")
  },
  {
    name: "atoms-typography",
    path: "/atoms/typography",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/atoms/typography.vue")
  },
  {
    name: "examples-form-field-validation",
    path: "/examples/form-field-validation",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/examples/form-field-validation.vue")
  },
  {
    name: "examples-form-validation",
    path: "/examples/form-validation",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/examples/form-validation.vue")
  },
  {
    name: "examples",
    path: "/examples",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/examples/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/index.vue")
  },
  {
    name: "molecules-accordion",
    path: "/molecules/accordion",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/accordion.vue")
  },
  {
    name: "molecules-badge",
    path: "/molecules/badge",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/badge.vue")
  },
  {
    name: "molecules-breadcrumbs",
    path: "/molecules/breadcrumbs",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/breadcrumbs.vue")
  },
  {
    name: "molecules-button",
    path: "/molecules/button",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/button.vue")
  },
  {
    name: "molecules-card",
    path: "/molecules/card",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/card.vue")
  },
  {
    name: "molecules-checkbox",
    path: "/molecules/checkbox",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/checkbox.vue")
  },
  {
    name: "molecules-collapse",
    path: "/molecules/collapse",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/collapse.vue")
  },
  {
    name: "molecules-data-table-simple",
    path: "/molecules/data-table-simple",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/data-table-simple.vue")
  },
  {
    name: "molecules-data-table",
    path: "/molecules/data-table",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/data-table.vue")
  },
  {
    name: "molecules-file-input",
    path: "/molecules/file-input",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/file-input.vue")
  },
  {
    name: "molecules-form-message",
    path: "/molecules/form-message",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/form-message.vue")
  },
  {
    name: "molecules",
    path: "/molecules",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/index.vue")
  },
  {
    name: "molecules-modal",
    path: "/molecules/modal",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/modal.vue")
  },
  {
    name: "molecules-pagination",
    path: "/molecules/pagination",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/pagination.vue")
  },
  {
    name: "molecules-popover",
    path: "/molecules/popover",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/popover.vue")
  },
  {
    name: "molecules-progress-circle",
    path: "/molecules/progress-circle",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/progress-circle.vue")
  },
  {
    name: "molecules-progress",
    path: "/molecules/progress",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/progress.vue")
  },
  {
    name: "molecules-radio-button",
    path: "/molecules/radio-button",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/radio-button.vue")
  },
  {
    name: "molecules-radio-cards",
    path: "/molecules/radio-cards",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/radio-cards.vue")
  },
  {
    name: "molecules-rating",
    path: "/molecules/rating",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/rating.vue")
  },
  {
    name: "molecules-segmented-control",
    path: "/molecules/segmented-control",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/segmented-control.vue")
  },
  {
    name: "molecules-skeleton",
    path: "/molecules/skeleton",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/skeleton.vue")
  },
  {
    name: "molecules-slider",
    path: "/molecules/slider",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/slider.vue")
  },
  {
    name: "molecules-support",
    path: "/molecules/support",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/support.vue")
  },
  {
    name: "molecules-tabs",
    path: "/molecules/tabs",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/tabs.vue")
  },
  {
    name: "molecules-text-input",
    path: "/molecules/text-input",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/text-input.vue")
  },
  {
    name: "molecules-textarea",
    path: "/molecules/textarea",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/textarea.vue")
  },
  {
    name: "molecules-verification-code",
    path: "/molecules/verification-code",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/verification-code.vue")
  },
  {
    name: "molecules-video",
    path: "/molecules/video",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/video.vue")
  },
  {
    name: "molecules-view-more",
    path: "/molecules/view-more",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/molecules/view-more.vue")
  },
  {
    name: "organisms-carousel",
    path: "/organisms/carousel",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/carousel.vue")
  },
  {
    name: "organisms-cta-banner",
    path: "/organisms/cta-banner",
    meta: cta_45bannerWzSq6JCZGAMeta || {},
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/cta-banner.vue")
  },
  {
    name: "organisms-cta-card",
    path: "/organisms/cta-card",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/cta-card.vue")
  },
  {
    name: "organisms-error-page",
    path: "/organisms/error-page",
    meta: error_45page3OkWOiB6NcMeta || {},
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/error-page.vue")
  },
  {
    name: "organisms-file-preview-modal",
    path: "/organisms/file-preview-modal",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/file-preview-modal.vue")
  },
  {
    name: "organisms-footer",
    path: "/organisms/footer",
    meta: footer9tpWUOFK1wMeta || {},
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/footer.vue")
  },
  {
    name: "organisms-form",
    path: "/organisms/form",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/form.vue")
  },
  {
    name: "organisms",
    path: "/organisms",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/index.vue")
  },
  {
    name: "organisms-nav-bar",
    path: "/organisms/nav-bar",
    meta: nav_45barcr3gCycKgfMeta || {},
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/nav-bar.vue")
  },
  {
    name: "organisms-reviews-io-card-carousel",
    path: "/organisms/reviews-io-card-carousel",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/reviews-io-card-carousel.vue")
  },
  {
    name: "organisms-support-card",
    path: "/organisms/support-card",
    meta: support_45card7TVNwA41gFMeta || {},
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/support-card.vue")
  },
  {
    name: "organisms-zip-code-form",
    path: "/organisms/zip-code-form",
    component: () => import("/home/runner/work/es-ds/es-ds/es-ds-docs/pages/organisms/zip-code-form.vue")
  }
]